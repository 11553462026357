<template>
  <div>
    <component
      :is="component"
      :idAntenna="idAntenna"
      :type="type"
      :serviceView="service"
      @changeComponent="changeComponent"
      @service="serviceManager"
    />
  </div>
</template>

<script>
export default {
  name: "Antennas",
  components: {
    ListAntenna: () => import("../components/antennas/ListAntennas"),
    DetailsAntenna: () => import("../components/antennas/DetailsAntenna"),
    ServiceAntenna: () => import("../components/antennas/ServiceAntenna"),
  },
  data: () => ({
    component: "ListAntenna",
    idAntenna: "",
    type: "",
    service: {}
  }),
  methods: {
    changeComponent(component, idAntenna = null) {
      if (idAntenna) this.idAntenna = idAntenna;
      this.type = "new";
      this.component = component;
    },
    serviceManager(type, service, idAntenna) {
      this.idAntenna = idAntenna;
      this.type = type;
      this.service = Object.assign({}, service);
      this.component = "ServiceAntenna"
    },
  },
};
</script>

<style lang="scss" scoped>
</style>